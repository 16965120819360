#page {
	// Product list
	.thumbnail-container {
		.product-image {
			background: $light-grey;
			overflow: hidden;

			a {
				padding: 15px;
			}

			form {
				margin: 0;
			}
		}

		.product-title {
			line-height: 1.3;

			a {
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 0.32px;
				color: $secondary;

				&:hover {
					color: $primary;
				}
			}
		}

		.product-price-and-shipping {
			margin-top: 10px;

			.price {
				color: $dark-grey;
			}
		}
	}

	#btn_view_product {
		padding: 0;

		span {
			display: none;
		}
	}

	.product-flags .product-flag {
		color: $secondary;
	}

	.btn-tertiary {
		padding: 9px 12px 9px 9px;
		background: $light-grey;
		color: $dark-grey;
	}

	.active_filters {
		background: $light-grey;
		padding: 0.625rem 0.875rem 0;

		.active-filter-title {
			color: $secondary;
			text-transform: uppercase;
			letter-spacing: .96px;
			font-size: 16px;
		}

		.filter-block {
			a {
				bottom: 12px;
			}
		}
	}

	.products-selection .sort-by-row,
	.products-sort-order .select-title {
		color: $dark-grey;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.28px;
		border-bottom-color: #CAC6C6;
	}

	.products-selection {
		.showing {
			color: #000;
			font-size: 14px;
			line-height: 26px;
			letter-spacing: 0.28px;
		}

		.sort-by,
		p {
			letter-spacing: 0.28px;
		}
	}

	.sort-by-row {
		@include mobile-only {
			flex-direction: column;

			.products-sort-order {
				width: 100%;
				max-width: 300px;
				margin-bottom: 20px;
			}

			.filter-button {
				min-width: unset;
				width: auto;
				margin-bottom: 15px;
			}

			#search_filter_toggler {
				padding: 20px 40px;
				color: $light-grey;
				font-size: 16px;
				font-weight: 700;
				line-height: 14px;
				letter-spacing: 0.32px;

				&:after {
					content: 'PRODUCTS';
				}
			}
		}
	}

	// Product Details Page
	.product-detail.product-image-gallery {
		.product-thumb-images {
			.thumb-container {
				width: 100%;
			}

			.thumb {
				border: 1px solid $grey-border;

				&.selected {
					border-color: $primary;
				}
			}
		}
	}

	.more-info-product {
		padding: 0;

		.title-info-product {
			margin: 30px 0;
		}
	}

	.more-info-product,
	.product-description {
		h1, h2, h3, h4, h5, h6 {
			color: $secondary;
		}
	}

	.zoomContainer {
		border: 1px solid $primary;
	}

	#product-details {
		section:last-child {
			display: none;
		}

		.product-features {
			.data-sheet {
				margin-bottom: 0;
			}

			h3 {
				font-size: 16px;
				letter-spacing: .96px;
				color: $secondary;

			}
		}
	}

	h1.product-detail-name {
		color: $secondary;
		margin-bottom: 16px;

		@include large {
			font-size: 42px;
			letter-spacing: 1.68px;
		}
	}

	.product-price {
		color: $secondary;
	}

	.product-quantities .label {
		color: $secondary;
	}

	.products-accordion {
		.card-header {
			a {
				font-weight: 700;
				letter-spacing: .96px;
				color: $secondary;

				&:hover {
					color: $primary;
				}
			}
		}
	}

	.plist-1 .cart .btn-product {
		background: $secondary;

		&:hover {
			background: $primary;
		}
	}

	.products-accordion .card-header a:after {
		color: $secondary;
	}

	.h5.products-section-title {
		text-transform: uppercase !important;
		color: $dark-grey;
		font-size: 30px;
		line-height: 38.5px;
		letter-spacing: 2.4px;
	}

	.card-block {
		ul {
			margin-left: 13px;

			li {
				list-style: disc;
				margin-bottom: 8px;
			}
		}
	}

	.product-actions {
		.control-label {
			font-size: 18px;
			line-height: 29.1px;
			letter-spacing: 1.44px;
			color: $dark-grey;
			margin-bottom: 15px;
		}

		+ .line {
			display: none;
		}
	}

	.product-prices {
		flex-direction: column;
		align-items: flex-start;

		.tax-shipping-delivery-label {
			margin-left: 0;
			max-width: 622px;

			.wkpreview {
				margin-top: 16px;
				gap: 4px;
				width: 100% !important;

				p {
					margin: 0;
				}
			}
		}
	}

	.sz-gallery-img {
		margin-bottom: 24px;
	}

	.product-variants > .product-variants-item {
		flex-direction: column;
		align-items: unset;
	}

	.product-attachments {
		display: grid;
	    gap: 16px;

	    @include large {
	    	grid-template-columns: 1fr 1fr;
	    }

		> h3 {
			display: none;
		}

		.attachment {
			background: #F6F6F6;
			padding: 16px 16px 16px 46px;
			position: relative;

			&:before {
				content: '\f15b';
				font-size: 16px;
				color: $secondary;
				font-family: 'FontAwesome';
				display: block;
				position: absolute;
				left: 16px;
				top: 16px;
			}

			p {
				display: none;
			}

			a {
				display: block;
			}
		}
	}
}

[data-elementor-device-mode="mobile"] #thumb-gallery {
	display: none !important;
}

#category {
	.sidebar {
		#search_filters {
			border-color: $light-grey;

			@include mobile-only {
				border: none;
			}

			p.text-uppercase.h6 {
				color: $dark-grey;
				font-size: 24px;
				font-weight: 700;
				line-height: 29.1px;
				letter-spacing: 1.92px;
			}

			.facet {
				border-color: $light-grey;

				@include mobile-only {
					margin-bottom: 0;
				}

				ul {
					margin-bottom: 22px;

					li {
						@include mobile-only {
							border: none;
							padding-bottom: 0;

							&:first-child {
								padding-top: 0;
							}
						}
						
						.facet-label {
							background: $light-grey;
							padding: 10px;
							margin-bottom: 5px;

							@include mobile-only {
								margin-bottom: 0;
							}
						}

						a {
							color: $secondary;
							font-size: 14px;
							line-height: 26px; /* 185.714% */
							letter-spacing: 0.28px;
						}
					}
				}

				.facet-title {
					color: $primary;
					text-transform: uppercase;
					font-size: 16px;
					font-weight: 700;
					letter-spacing: 0.96px;

					@include mobile-only {
						padding: 25px 10px;
					}
				}

				.custom-checkbox input[type="checkbox"] + span {
					background: #fff;

					.checkbox-checked {
						margin: -1px 0 0 -1px;
					}
				}

				.navbar-toggler {
					margin-top: 12px;

					i {
						font-weight: 700;
						color: $secondary;
					}
				}
			}
		}

		#search_filter_controls {
			button {
				background: $secondary;
				padding: 20px 40px;
				color: $light-grey;
				font-size: 0;
				font-weight: 700;
				line-height: 0;
				letter-spacing: 0.32px;

				i {
					display: none;
				}

				&:after {
					content: 'SHOW RESULTS';
					font-size: 16px;
					line-height: 14px;
				}
			}
		}
	}

	#search_filter_toggler {
		@include mobile-only {
			background: $secondary;
		}
	}

	h1 {
		display: none !important;
	}

	#category-description {
		margin-top: 0;

		h2 {
			color: $dark-grey;
			text-align: center;
			@include font-bold;
			font-size: 30px;
			font-style: normal;
			font-weight: 700;
			letter-spacing: 2.4px;

			@include medium {
				display: none;
			}
		}
	}
}