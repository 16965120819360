#footer {
	.footer_logo {
		text-align: center;
	}

	.footer-left {
		p {
			font-size: 12px;
			font-weight: 400;
			line-height: 22px;
			color: $darker-grey;
		}
	}

	.footer-right {
		p {
			text-align: center;
			color: $dark-grey;
		}
	}

	.block_newsletter {
		.btn {
			float: unset !important;
			height: 27px;
			line-height: 27px;
			border: unset;
			padding: 0 8px;
			color: $secondary;
			font-size: 15px;
			font-weight: 700;
			letter-spacing: 0.3px;
			position: relative !important;
			margin: 30px 0 0;
			padding: 0;
			text-transform: capitalize;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			gap: 10px;

			i {
				display: block;

				&:before {
					content: '\f00c';
				}
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				background: $primary-light;
				bottom: 2px;
				left: -10px;
				height: 7px;
				transition: all 0.3s;
				width: calc(100% + 20px);
			}

			&:hover {
				&:before {
					height: 23px;
				}
			}

			span, i {
				position: relative;
				z-index: 1;
			}
		}

		input[type=email] {
			text-align: center;
			border-bottom: 1px solid #CAC6C6;
			font-size: 14px;
			height: 35px;
			padding: 6px;

			&::placeholder {
				color: #CAC7C7;
				font-size: 14px;
			}
		}

		.row .row div:first-child {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;
		}
	}

	.title_block {
		font-size: 16px;
		letter-spacing: 0.96px;
	}

	.linklist-menu {
		.item-text {
			color: $secondary;
			font-size: 14px;
			line-height: 26px;
			letter-spacing: 0.28px;
			display: block;
			text-align: center;

			&:hover {
				color: $primary;
			}
		}
	}

	.light p {
		color: #cac6c6;
	}
}