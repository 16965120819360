#page {
	.btn_link {
		.elementor-button-link {
			padding: 0 8px;
			color: $secondary;
			font-size: 15px;
			font-weight: 700;
			letter-spacing: 0.3px;

			&:before {
				background: $primary-light;
				bottom: -3px;
				height: 7px;
			}

			&:hover {
				&:before {
					height: 23px;
				}
			}
		}

		&.btn-light {
			.elementor-button-link {
				color: $light-grey !important;

				&:before {
					background: #c02d2d;
				}
			}
		}

		&.red-hover:hover {
			.elementor-button-link {
				color: $primary;
			}
		}

		&.btn-red-line {
			.elementor-button-link:before {
				background: $primary;
			}
		}
	}

	label, .label {
		color: $secondary;
	}

	.label-pill {
		color: #FFF;
	}

	.custom_choosefile .button_choosefile {
		color: $light-grey;
		text-transform: uppercase;
		@include font-bold;
		background: $secondary;
	}

	.wpcf7-submit {
		font-size: 16px;
		letter-spacing: 0.32px;
		color: $light-grey;
		background: $secondary;
		padding: 15px 26px;

		&:hover {
			background: $primary;
		}
	}

	.wpcf7 form > h3 {
		display: none;
	}

	.input-group .btn {
		background: $secondary;
		color: #FFF;
	}

	.btn {
		font-size: 15px;
		font-weight: 700;
		letter-spacing: 0.3px;
		
		&.btn-primary {
			background-color: $primary;

			&:hover {
				background: #c02d2d;
				color: $light-grey;
			}
		}
	}

	.bootstrap-touchspin input.form-control, .bootstrap-touchspin input.input-group {
		border-color: $secondary;
		@include font-bold;
		color: $secondary;
	}

	.btn[for="file-upload"] {
		background: $secondary;
		font-size: 11px;
		padding-top: 6px;

		&:hover {
			background: $primary;
		}
	}

	form .form-control-label {
		color: $secondary;
	}

	.form-control-select {
		cursor: pointer;
		width: 100%;
		max-width: 440px;
		color: $secondary;
		border-color: $secondary;
		font-size: 16px;
		@include font-bold;
		line-height: 26px;
		letter-spacing: 0.32px;
		padding: 2px 30px 2px 15px;
		height: 47px;
	}

	#product-description-short-1 p {
		color: $dark-grey;
	}

	.product-variants-item {
		ul {
			margin-bottom: 5px;
			display: flex;
			flex-direction: column;
			gap: 20px;

			@include large {
				flex-direction: row;
			}

			li {
				margin: 0;
				float: none !important;
				width: 100%;

				label {
					display: block;
					width: 100%;
					text-align: left;
					box-shadow: none;
				}

				.radio-label {
					display: block;
					box-shadow: none;
					padding: 14.1px 14px 12.2px 55px;
					background-color: $light-grey;
					border: 1px solid $light-grey;
					color: $dark-grey;
					font-size: 16px;
					@include font-bold;
					letter-spacing: 0.32px;
					background-image: url(img/close.svg);
					background-repeat: no-repeat;
					background-position: 15px 50%;
					background-size: 23px;
				}

				.input-radio:checked + span {
					background-color: $light-green;
					border-color: $green;
					color: $green;
					background-image: url(img/check.svg);
				}
			}
		}
	}
}

.no-click {
	pointer-events: none;
}

a {
	color: $secondary;
}

