#page #wrapper {
	.breadcrumb {
		position: relative;
		margin-bottom: 40px;
		z-index: 1;
		display: block !important;
		padding: 56px 0;

		&[data-depth="1"] {
			display: none !important;
		}

		&:after {
			content: '';
			display: block;
			background-image: url(/img/cms/ufh-pattern-stroke-2.svg);
			background-size: 450px;
			background-repeat: no-repeat;
			background-position: center;
			width: 136px;
			height: 500px;
			position: absolute;
			top: -182px;
			left: 50px;
			z-index: -1;
			transform: rotate(90deg);
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 200vw;
			background: $light-grey;
			z-index: -2;
			transform: translateX(-50vw);
		}

		* {
			font-size: 15px;
			letter-spacing: 0.75px;
			color: $secondary;
		}

		li {
			&:after {
				content: '>';
			}

			&:last-child {
				&:after {
					content: '';
				}
			}
		}
	}
}