// Colours 
$primary:       #F03838;
$primary-light: #FFC5C8;
$secondary:     #2B4162;
$dark-grey:     #6C6C6C;
$darker-grey:   #545454;
$light-grey:    #F3F3F3;
$grey-border:   #EAEAEA;
$dark:          #222222;
$light-green:   #D0F3CB;
$green:         #41BE2F;

// Media queries
$small-only:        479px;
$small:             480px;
$medium:            768px;
$mobile-only:       767px;
$large:             1024px;
$xl:                1301px;
$xxl:               1600px;

@mixin min($size) {
    @media only screen and (min-width:$size) {
        @content
    }
}

@mixin max($size) {
    @media only screen and (max-width:$size) {
        @content
    }
}

@mixin small {
    @media screen and (min-width: $small) {
        @content;
    }
}

@mixin small-only {
    @media screen and (max-width: $small-only) {
        @content;
    }
}

@mixin medium {
    @media screen and (min-width: $medium) {
        @content;
    }
}

@mixin medium-max {
    @media screen and (max-width: $medium-max) {
        @content;
    }
}

@mixin medium-only {
    @media screen and (min-width: $medium) and (max-width: $mobile-only) {
        @content;
    }
}

@mixin large {
    @media screen and (min-width: $large) {
        @content;
    }
}

@mixin xl {
    @media screen and (min-width: $xl) {
        @content;
    }
}

@mixin xxl {
    @media screen and (min-width: $xxl) {
        @content;
    }
}

@mixin mobile-only {
    @media screen and (max-width: $mobile-only) {
        @content;
    }
}