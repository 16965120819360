body {
	p {
		color: $dark-grey;
	}
}

.modal-body {
	.h3 {
		color: $secondary;
		text-transform: uppercase;
		font-size: 18px !important;
	}

	.btn {
		background: $secondary;
		@include font-bold;
		padding-top: 12px;
		color: $light-grey;

		&:hover {
			background: $primary;
		}
	}
}

leo-fly-cart.type-fixed {
	z-index: 99999;
}

.leo-fly-cart-total {
	background: $secondary !important;
	line-height: 22px !important;
}

.elementor-widget-heading .elementor-heading-title > a {
	color: $secondary;
}

#page {
	#content-wrapper {
		h1 {
			@include h2;
		}
	}

	.page-header {
		* {
			color: $secondary;
		}
	}

	#leo_search_top_button {
		background: $secondary;
	}

	.popup-content.dropdown-menu {
		a, .title {
			color: $secondary;
		}

		a {
			@include font-bold;

			&:hover {
				color: $primary;
			}
		}
	}

	.elementor-widget-icon-box.elementor-view-framed .elementor-icon,
	.elementor-widget-icon-box.elementor-view-default .elementor-icon {
		color: $secondary;
		fill: $secondary;
	}

	.elementor-icon-box-content {
		font-size: 16px;
		@include font-bold;
		padding-top: 18px;

		span {
			color: $secondary;
		}
	}

	.popup-content {
		border-color: $secondary;

		&.dropdown-menu {
			min-width: 230px;
			padding: 13px 13px 8px 13px;

			.row div:first-child {
				display: none;
			}

			.col-sm-6 {
				width: 100%;
			}

			.user-info {
				a {
					display: block;
					background: $secondary;
					color: $light-grey;
					text-transform: uppercase;
					font-size: 16px;
					letter-spacing: .32px;
					text-align: center;
					padding: 18px 18px 16px;

					&:hover {
						background: $primary;
					}
				}
			}
		}
	}

	.page-cms {
		ul {
			margin-left: 13px;
			margin-bottom: 24px;

			li {
				list-style: disc;
				margin-bottom: 8px;
			}
		}

		.elementor-text-editor {
			p {
				margin-bottom: 24px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			h1, h2, h3, h4, h5, h6 {
				color: $secondary;
				margin-bottom: 6px;
			}
		}
	}

	.pagination .page-list {
		li {
			padding: 0;
			border: unset;

			a {
				padding: 8px 10px;
				width: 35px;
				height: 35px;
				text-align: center;
				border: 1px solid $grey-border;
				color: $secondary;
				@include font-bold;

				&:hover {
					border-color: $secondary;
					color: $secondary;
				}
			}

			&.current a {
				background: $primary;
				border-color: $primary;
				color: #FFF;
			}
		}
	}

	.elementor-accordion-item {
		p strong {
			color: $secondary;
		}

		.elementor-tab-title {
			color: $secondary;
			padding: 15px 20px;
			line-height: 15px;
		}
	}

	.alert-warning {
		background-color: $primary-light;
		border-color: $primary;
	}

	.primary {
		.elementor-heading-title {
			color: $primary;
		}
	}

	.has-line {
		.elementor-heading-title::after {
			content: '';
			height: 2px;
			width: 75px;
			display: flex;
			margin-top: 8px;
		}

		&.centred .elementor-heading-title::after {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.secondary {
		.elementor-heading-title {
			color: $secondary;
		}

		&.has-line {
			.elementor-heading-title:after {
				background-color: $secondary;
			}
		}
	}

	.dark-grey {
		.elementor-heading-title {
			color: $dark-grey;
		}
	}

	.light-grey {
		.elementor-heading-title {
			color: $light-grey;
		}

		&.has-line {
			.elementor-heading-title:after {
				background-color: $light-grey;
			}
		}
	}

	.elementor-widget-text-editor {
		p {
			color: $dark-grey;
		}
	}

	.promo-boxes {
		padding: 0 10px;

		.elementor-row {
			gap: 37px;
			flex-wrap: unset;
			flex-direction: column;

			@include medium {
				flex-direction: row;
				gap: 15px;
			}

			@include large {
				gap: 37px;
			}
		}

		.elementor-widget-heading,
		.elementor-widget-button,
		.elementor-widget-text-editor {
			position: relative;
			z-index: 1;
		}

		.elementor-column-wrap {
			min-height: 542px;
			padding: 0;
		}

		p {
			font-size: 20px;
			line-height: 26px;
			letter-spacing: 0.4px;
		}

		li {
			font-size: 20px;
			line-height: 26px;
			letter-spacing: 0.4px;
			color: #fff;
			margin-bottom: 5px;
		}

		strong {
			background: #fff;
			color: $primary;
			font-weight: normal;
			padding: 5px 10px;
			display: inline-block;
			margin-top: 10px;
		}

		.elementor-widget-image-box {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			overflow: hidden;

			* {
				height: 100%;
				width: 100%;
				text-align: center;
			}

			img {
				object-fit: cover;
				object-position: center;
			}

			&.trade-account {
				img {
					object-fit: contain;
					object-position: center bottom;
					max-width: 290px;
				}
			}
		}
	}
}