@mixin font-regular {
	font-family: 'space_groteskregular';
}

@mixin font-medium {
	font-family: 'space_groteskmedium';
}

@mixin font-bold {
	font-family: 'space_groteskbold';
}

@mixin font-script {
	font-family: 'Kalam';
}

@mixin h2 {
	font-size: 24px;
	letter-spacing: 1.2px;
	line-height: 32px;

	@include large {
		font-size: 30px;
		line-height: 38.5px;
		letter-spacing: 2.4px;
	}
}

#page {
	h1, h2, h3, h4, h5, h6 {
		text-transform: uppercase;
		color: $secondary;
	}

	h1 {
		font-size: 31px;
		letter-spacing: 2.4px;
		
		@include large {
			font-size:	55px;
			letter-spacing: 2.2px;
		}
	}

	h2 {
		@include h2;
	}

	h3 {
		font-size: 24px;
		letter-spacing: 1.2px;
		line-height: 32px;

		@include large {
			font-size: 30px;
			line-height: 38.5px;
			letter-spacing: 2.4px;
		}
	}

	.mobile h3 {
		font-size: 24px;
		letter-spacing: 1.2px;
	}

	h4,
	.box h3 {
		font-size: 16px;
		letter-spacing: 0.96px;
	}
}