#header {
	box-shadow: unset !important;

	.leo-megamenu {
		.navbar-nav {
			li.parent {
				a.nav-link {
					text-transform: uppercase;
					@include font-regular;
					letter-spacing: 0.45px;
					color: $secondary;
					padding: 30px 10px;

					&:before {
						background: $primary-light;
					}
				}
			}
		}
	}

	.col_logo,
	.col_info {
		width: auto;
	}

	.col_menu {
		width: calc(100% - 160px - 135px);
	}

	@media (min-width: 992px) and (max-width: 1427px) {
		.col_logo img { width: 100px; }

		.col_info {
			.elementor-element-ee74cc4,
			.elementor-element-e1fb017 {
				display: none;
			}

			.elementor-widget-container {
				margin: 0;
			}
		}

		.col_menu { width: calc(100% - 115px - 50px); }
	}

	.menu-title {
		color: $secondary;
		font-size: 14px;
		letter-spacing: 0.28px;
	}

	.cart-products-count {
		font-size: 10px !important;
		font-weight: normal !important;
		padding-top: 1px;
	}

	.input_hidden #leo_search_block_top .title_block,
	#leo_block_top .popup-title i::before,
	i.shopping-cart::before {
		color: $dark-grey !important;
	}

	> .elementor-container {
		max-width: 2000px;
	}
}

.leo-megamenu .dropdown-menu li a {
	color: $secondary;
}

.mega-col .widget-html a > img  {
	border: 1px solid $grey-border;
}

.off-canvas-active > .off-canvas-nav-megamenu.active {
	transform: translateX(320px);
}



.off-canvas-nav-megamenu {
	.offcanvas-mainnav {
		left: -320px;
		width: 320px;
	}

	.nav-link.has-category {
		color: $secondary !important;
		font-size: 15px !important;
		line-height: normal;
		letter-spacing: 0.45px;
		@include font-bold;
		padding: 20px 19px !important;
	}

	.caret {
		top: 19px !important;
		right: 4px !important;
	}

	.dropdown-sub,
	.dropdown-menu {
		.menu-title, a {
			padding: 10px 6px !important;
			color: $secondary !important;
			font-size: 14px !important;
			font-weight: 700 !important;
			letter-spacing: 0.28px;
			text-transform: capitalize !important;
		}

		a {
			color: $dark-grey !important;
			padding: 8px 0 !important;
			display: block;
		}
	}

	.dropdown-menu .menu-title {
		color: $dark-grey !important;
		padding: 10px 9px !important;
	}

	.level0 {
		margin-bottom: 5px;

		&:last-child {
			margin-bottom: 0;
		}

		li {
			width: 100%;

			a {
				background: $light-grey;
				padding: 15px 10px !important;
				display: block;
			}
		}
	}

	ul.nav-links {
		padding: 0;

		li {
			margin-bottom: 5px;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				background: $light-grey;
				padding: 10px !important;
			}
		}
	}

	.manu-logo a {
		border: 1px solid $grey-border;
		margin-bottom: 5px;
	}
}

