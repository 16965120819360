.hero-large-product,
.hero-small-product,
.hero-small-image,
.hero-med-image {
	.elementor-widget-wrap {
		height: 100%;
	}

	.elementor-image-box-img {
		width: 100%;
		margin: 0 !important;
	}

	.elementor-widget-image-box {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		* {
			height: 100%;
			width: 100%;
			text-align: center;
		}

		img {
			object-fit: contain;
			object-position: 50% 75%;
			padding: 10%;
			max-width: 800px;

			@include large {
				object-position: 50% 50%;
			}
		}
	}

	.elementor-widget-heading,
	.elementor-widget-text-editor {
		position: relative;
		z-index: 1;
	}
}

.hero-small-image,
.hero-med-image {
	.elementor-image-box-img {
		overflow: hidden;
	}

	.elementor-widget-image-box {
		img {
			object-fit: cover;
			object-position: center;
			max-width: unset;
			padding: 0;
		}
	}
}

.hero-med-image {
	.elementor-widget-image-box {
		img {
			object-position: 50% 54%;
		}
	}

	.elementor-widget-text-editor {
		ul {
			display: inline-flex;
			flex-direction: column;
			align-items: flex-start;
		}

		li {
			color: $secondary;
			background: #FFF;
			font-size: 20px;
			letter-spacing: 0.4px;
			padding: 10px 20px;
		}
	}
}

.hero-small-image,
.hero-small-product,
.hero-med-image {
	height: 500px;

	@include medium {
		height: 417px;

		.btn_link {
			display: none;
		}
	}

	@include large {
		height: 485px;

		.btn_link {
			display: block;
		}
	}
}

.hero-small-product {
	.elementor-widget-image-box {
		img {
			object-position: 50% 75%;
			padding: 10%;
			max-width: 450px;

			@include xl {
				object-position: 50% 100%;
			}
		}
	}

	&.position-bottom {
		.elementor-widget-image-box {
			img {
				object-position: center 70%;
				padding: 10% 10% 0 10%;

				@include xl {
					object-position: center 82%;
				}
			}
		}
	}
}

#page {
	.hero-carousel {
		.elementor-col-33 img {
			max-width: 105px !important;
		}

		.block-carousel-image-container {
			display: flex;
			flex-direction: column-reverse;
			height: 560px;

			@include large {
				height: 939px;
			}

			.ap-more-info + a {
				margin: auto 50px;
			}
		}

		.img-fluid {
			max-height: 440px;

			@include large {
				max-height: 590px;
			}
		}

		h3 {
			color: $secondary;
			margin: 45px 45px 15px 45px;
		}

		.elementor-button {
			background: transparent;
			margin: 0 0 0 45px;
		}

		.slick-dots {
			margin-bottom: 5px;
		}
	}
}

.block-features {
	ul {
		display: flex;
		gap: 20px;
		justify-content: center;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;

		li {
			background: $light-grey;
			color: $primary;
			text-transform: uppercase;
			font-size: 20px;
			line-height: 26px;
			letter-spacing: 1.6px;
			padding: 20px 15px;
			display: flex;
			flex: 1 0 0;
			min-height: 94px;
			min-width: 320px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;

			strong {
				@include font-regular;
				font-weight: normal;
			}

			em {
				@include font-script;
				text-decoration: underline;
			}
		}
	}
}