#page {
	#content-wrapper {
		h1.step-title {
			@include h2;
			color: $secondary;
			font-size: 18px !important;
		}
	}

	.media-list {
		.product-name a {
			color: $secondary;
		}
	}

	
}

#checkout #wrapper {
	border-top: 1px solid $grey-border;
	padding-top: 50px;

	.checkout-step {
		.continue {
			background: $primary;
			color: #FFF;

			&:hover {
				background: $secondary;
			}
		}
	}

	.step-edit {
		color: $secondary !important;
	}

	.address-item {
		&.selected {
			border-width: 2px;
		}

		.h4 {
			color: $secondary;
			margin-top: 8px;
			font-size: 18px;
		}

		.custom-radio {
			margin-top: -5px;
			margin-right: 2px !important;
		}

		.address {
			@include font-regular;
			color: $secondary;
		}

		footer a {
			color: $secondary !important;
		}
	}
}