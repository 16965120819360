#blockcart-modal {
	.cart-content .cart-content-btn {
		.btn {
			padding-top: 13px;
			font-weight: 700;

			&.btn-primary {
				background: $primary;
			}
		}
	}

	.modal-header {
		background: $light-grey;

		i {
			color: $dark;
		}

		.modal-title {
			color: $dark;
		}
	}

	.product-name {
		font-size: 16px;
		font-weight: 700;
		letter-spacing: .96px;
		text-transform: uppercase;
		color: $secondary;
		margin-top: 24px;

		@include medium {
			margin-top: 0;
		}
	}

	.product-price {
		font-size: 14px;
		color: $secondary !important;
	}

	.modal-body .divide-right {
		span {
			color: $secondary;
		}
	}
}

.leo-fly-cart-slidebar {
	.btn-primary {
		background: $light-grey;
		@include font-bold;
		color: $secondary;

		&:hover {
			background: $secondary;
			color: #FFF;
		}
	}

	.cart-dropdow-checkout {
		background: $primary;
		color: #FFF;
	}

	.leo-remove-from-cart,
	.leo-dropdown-cart-item a {
		color: $secondary;
	}
}

#page {
	.cart-container {
		h1 {
			@include h2;
		}
	}

	.leo-dropdown-cart-content {
		border-color: $secondary;

		.leo-dropdown-cart-total {
			padding: 13px;
		}

		.leo-dropdown-cart-subtotals {
			padding: 13px;
		}

		.product-quantity {
			input {
				color: $secondary;
				@include font-bold;
				border: 1px solid $secondary;
			}

			.leo-bt-product-quantity {
				background: $secondary;
				color: $light-grey;
				border: 1px solid $secondary;
			}
		}

		.leo-dropdown-cart-item {
			padding: 13px;

			.leo-remove-from-cart {
				right: 8px;
				color: $dark-grey;

				&:hover {
					color: $primary;
				}
			}
		}

		.leo-cart-dropdown-action {
			display: flex;
			flex-direction: column;
			padding: 13px;

			a {
				text-transform: uppercase;
				padding: 18px 18px 16px;

				&:first-child {
					background: $secondary;;
					margin-bottom: 10px;
				}
			}
		}
	}

	.cart-items {
		.product-line-info {
			a {
				color: $secondary;
				font-weight: 700;
				letter-spacing: .32px;
			}
		}
	}

	.cart-grid-body {
		.btn-primary {
			background: $light-grey;
			color: $secondary;
		}
	}

	.product-line-grid-body {
		.product-line-info.product-price .current-price,
		.product-line-info .value {
			color: $secondary;
		}
	}

	.product-line-info.select {
		margin-bottom: 8px;
	}

	.card-block.cart-summary-totals .cart-summary-line {
		padding: 0.5rem 0 0;
	}

	.cart-summary-line .value,
	.card-block.cart-summary-totals .cart-summary-line.cart-total .label {
		color: $secondary;
	}
}

.leo-dropdown-cart {
	.product-name a {
		color: $secondary;
		font-weight: 700;
		letter-spacing: .32px;
	}

	.value,
	.price {
		color: $secondary !important;
	}
}

#cart {
	#wrapper {
		border-top: 1px solid $grey-border;
		padding-top: 10px;
	}
}